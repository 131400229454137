import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Container, Nav, Navbar, NavDropdown, Offcanvas, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { AccountContext } from '../../contexts/Account';
import { useNavigate } from 'react-router-dom';
import { OrderContext } from '../../contexts/Order';
import { VatCountries, EuCountries } from '../../data/CountryData';
import Image from '../../components/Image';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import CountryDropDown from '../../components/CountryDropDown';
import InitAccountStateData from '../../data/InitAccountStateData';
import Transformer from '../../Transformer';

import styles from './styles.module.scss';

const MainNav = ({ openLogin, openRegister }) => {
  const tempDisableNewLinks = false;
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 992px)');
  const [account, dispatchAccount] = useContext(AccountContext);
  const [useMobileMenu, setUseMobileMenu] = useState(false);
  const [orderState, dispatchOrder] = useContext(OrderContext);
  const totalOrderQty = orderState.plans.reduce((a, b) => a + parseInt(b['quantity']), 0);
  const OrderTransformer = Transformer.Order(orderState);

  useEffect(() => {
    if (matches === useMobileMenu) return;

    setUseMobileMenu(matches);
  }, [matches, useMobileMenu]);

  const logout = () => {
    dispatchAccount(InitAccountStateData);

    navigate('/');
  };

  const updateVAT = () => {
    OrderTransformer.update('showIncVat', !orderState.showIncVat);
    OrderTransformer.updateTotal();

    const transformedOrder = OrderTransformer.getState();
    dispatchOrder({...transformedOrder});
  };

  const changeCurrency = (currencyCode) => {
    OrderTransformer.update('currency', currencyCode);
    OrderTransformer.updateTotal();

    const transformedOrder = OrderTransformer.getState();
    dispatchOrder({...transformedOrder});
  };

  const updateCountry = (country) => {
    // Update currency code
    if ('GB' === country) {
      OrderTransformer.update('currency', 'GBP');
    } else if (EuCountries.includes(country)) {
      OrderTransformer.update('currency', 'EUR');
    } else {
      OrderTransformer.update('currency', 'USD');
    }

    // Decide whether to show / hide VAT
    OrderTransformer.update('vat', VatCountries.includes(country));

    // Decide whether to include / exclude VAT
    OrderTransformer.update('showIncVat', VatCountries.includes(country));

    // Update country selection
    OrderTransformer.update('country', country);
    OrderTransformer.update('company_country', country);

    // Update totals
    OrderTransformer.updateTotal();

    const transformedOrder = OrderTransformer.getState();
    dispatchOrder({...transformedOrder});
  };

  return <>
    <Navbar
      key={'main-nav'}
      expand={!useMobileMenu}
      sticky="top"
      className={styles.navbar + ' ' + (!useMobileMenu ? 'd-none d-lg-block' : 'd-block d-lg-none') }
    >
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/">
          <Image src="images/logo192" width={64} height={54} alt="Purple IO" />
        </Navbar.Brand>
        <Navbar.Brand as={NavLink} to="/">
          <span className="fs-4 lh-1 d-block text-tertiary">Purple IO</span>
          <small className="fs-6">UK Data Centre</small>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`main-menu-nav`} />
        <Navbar.Offcanvas
          id={`main-menu-nav`}
          aria-labelledby={`offCanvasNavbarLabel-expand`}
          placement="end"
          className={styles.offCanvas}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offCanvasNavbarLabel-expand`} className="w-75">
              <Row>
                <Col>
                  <Image src="images/logo192" width={72} height={60} alt="Purple IO" />
                </Col>
                <Col>
                  <span className="fs-4 lh-1 text-tertiary">Purple IO</span><br />
                  <small className="fs-6">UK Data Centre</small>
                </Col>
              </Row>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className={styles.navLinks + (!useMobileMenu ? ' align-items-center ' : ' align-items-start ') + ' justify-content-end flex-grow-1 pe-3'}>
              <NavDropdown
                key={`nav-app-expand`}
                id={`nav-app-expand`}
                title={[
                  <FontAwesomeIcon key="app-icon" icon="th-large" className="text-tertiary me-2" />,
                  'Apps'
                ]}
                className="p-2"
              >
                <NavDropdown.Item key={'sftp-client'} as={NavLink} to="/client">
                  <Image src="images/sftpclient64" width={36} height={28} />&nbsp;
                  sFTP Client
                </NavDropdown.Item>
                <NavDropdown.Item key={'sftp-client-chrome'} as={NavLink} to="/chrome" className="align-items-center">
                  <span className="text-center d-inline-block" style={{width: 36}}>
                    <FontAwesomeIcon type="brand" icon="chrome" className="fs-3 yellow-text valign-middle" />
                  </span>&nbsp;
                  sFTP Client for Chrome
                </NavDropdown.Item>
                <NavDropdown.Divider key={'app-divider-1'} />
                <NavDropdown.Item key={'sftp-server'} as={NavLink} to="/server">
                  <Image src="images/sftpserver64" width={36} height={28} />&nbsp;
                  sFTP Server
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                key={`nav-hosting-server-expand`}
                id={`nav-hosting-server-expand`}
                title={[
                  <FontAwesomeIcon key="hosting-and-server-icon" icon="server" className="text-tertiary me-2" />,
                  'Hosting & Servers'
                ]}
                className="p-2"
              >
                <NavDropdown.Item key={'all-hosting-plans'} as={NavLink} to="/hosting">
                  All Hosting &amp; Server Plans
                </NavDropdown.Item>
                <NavDropdown.Divider key={'hosting-divider-1'} />
                <NavDropdown.Item key={'standard-hosting'} as={NavLink} to="/hosting/shared/standard">
                  <span className="text-center d-inline-block" style={{width: 30}}>
                    <FontAwesomeIcon icon="cloud" className="text-tertiary me-2" />
                  </span>
                  Standard Hosting
                </NavDropdown.Item>
                <NavDropdown.Item key={'premium-hosting'} as={NavLink} to="/hosting/shared/premium">
                  <span className="text-center d-inline-block" style={{width: 30}}>
                    <FontAwesomeIcon icon="cubes" className="text-tertiary me-2" />
                  </span>
                  Premium SSD Hosting
                </NavDropdown.Item>
                <NavDropdown.Divider key={'hosting-divider-2'} />
                <NavDropdown.Item key={'vps'} as={NavLink} to="/hosting/servers/virtual-private-servers">
                  <span className="text-center d-inline-block" style={{width: 30}}>
                    <FontAwesomeIcon icon="server" className="text-tertiary me-2" />
                  </span>
                  Virtual Private Servers
                </NavDropdown.Item>
                <NavDropdown.Item key={'dedicated-servers'} as={NavLink} to="/hosting/servers/dedicated-servers">
                  <span className="text-center d-inline-block" style={{width: 30}}>
                    <FontAwesomeIcon icon="hdd" className="text-tertiary me-2" />
                  </span>
                  Dedicated Servers
                </NavDropdown.Item>
                <NavDropdown.Item key={'co-location'} as={NavLink} to="/hosting/servers/co-location">
                  <span className="text-center d-inline-block" style={{width: 30}}>
                    <FontAwesomeIcon icon="globe-stand" className="text-tertiary me-2" />
                  </span>
                  Co-Location Services
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                key={`nav-help-and-support-expand`}
                id={`nav-help-and-support-expand`}
                title={[
                  <FontAwesomeIcon key="help-and-support-icon" icon="life-ring" className="text-tertiary me-2" />,
                  'Support'
                ]}
                className="p-2"
              >
                <NavDropdown.Item key={'app-documentation'} href="https://help.purpleio.uk">
                  <FontAwesomeIcon icon="book" className="text-tertiary me-2" />
                  App Documentation
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item key={'community-forums'} href="https://community.purpleio.uk">
                  <FontAwesomeIcon icon="comments" className="text-tertiary me-2" />
                  Community Forums
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item key={'contact'} as={NavLink} to="/company/contact">
                  <FontAwesomeIcon icon="envelope" className="text-tertiary me-2" />
                  Contact Purple IO
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/order">
                <Button variant="outline">
                  <FontAwesomeIcon icon="shopping-cart" className="white-text me-2" />
                  <Badge>{totalOrderQty > 1000 ? '1000+' : totalOrderQty}</Badge>
                </Button>
              </Nav.Link>
              <NavDropdown
                key={`nav-currency-expand`}
                id={`nav-currency-expand`}
                title={[
                  <FontAwesomeIcon key="currency-icon" icon={
                    'GBP' === orderState.currency ? 'pound-sign' : 'EUR' === orderState.currency ? 'euro-sign' : 'dollar-sign'
                  } className="text-tertiary me-2" />,
                  orderState.currency
                ]}
                rootCloseEvent={''}
                onClick={(e) => e.preventDefault()}
                className="p-2"
              >
                <NavDropdown.Item key={'currency-gbp'} onClick={() => changeCurrency('GBP')}>
                  <FontAwesomeIcon icon="pound-sign" className="text-tertiary me-2" />
                  GBP
                </NavDropdown.Item>
                <NavDropdown.Item key={'currency-eur'} onClick={() => changeCurrency('EUR')}>
                  <FontAwesomeIcon icon="euro-sign" className="text-tertiary me-2" />
                  EUR
                </NavDropdown.Item>
                <NavDropdown.Item key={'currency-usd'} onClick={() => changeCurrency('USD')}>
                  <FontAwesomeIcon icon="dollar-sign" className="text-tertiary me-2" />
                  USD
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {orderState.vat ? <>
                  <div className="p-2 ps-3 pe-3 white-text"  onClick={() => updateVAT()}>
                    <FontAwesomeIcon icon={orderState.showIncVat ? 'check' : 'times'} className="text-tertiary me-2" />
                    <label htmlFor="pt-1 include_exclude_vat">
                      {orderState.showIncVat ? 'Include' : 'Exclude'} VAT
                    </label>
                  </div>
                  <NavDropdown.Divider/>
                </> : ''}
                <div className="p-1 ps-3 pe-3">
                  <label className="text-tertiary mb-2">
                    Country
                  </label>
                  <CountryDropDown
                    onUpdate={(field, e) => updateCountry(e.target.value)}
                    value={orderState.country}
                  />
                </div>
              </NavDropdown>
              {!account ? (
                <Nav.Link as={NavLink} to="#">
                  <FontAwesomeIcon icon="spinner" className="fa-spin me-2"/>
                </Nav.Link>
              ) : account.user ? (
                <NavDropdown
                  key={`nav-account-expand`}
                  id={`nav-account-expand`}
                  title={<>
                    {account.user.image ? <img src={account.user.image} height={30} alt="Account Avatar" className={styles.userImg} /> : <FontAwesomeIcon icon={'user'} className={'me-2'} />}
                    {account.user.forename + ' ' + account.user.surname}
                  </>}
                  className="p-2"
                  align="end"
                >
                  <NavDropdown.Item key={'manage-account'} as={NavLink} className={'d-flex'} to="/account/manage">
                    <FontAwesomeIcon icon="user-edit" className="text-tertiary me-2" />
                    <span className={'d-flex'}>Manage Account</span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider key={'account-divider-1'} />
                  <NavDropdown.Item key={'my-orders'} as={NavLink} className={'d-flex'} to="/account/orders">
                    <FontAwesomeIcon icon="shopping-cart" className="text-tertiary me-2" />
                    <span className={'d-flex me-3'}>My Orders / Services</span>
                    <Badge className="ms-auto align-self-center">
                      {account.user.orders.length > 99 ? '100+' : account.user.orders.length}
                    </Badge>
                  </NavDropdown.Item>
                  <NavDropdown.Item key={'my-devices'} as={NavLink} className={'d-flex'} to="/account/devices">
                    <FontAwesomeIcon icon="desktop" className="text-tertiary me-2" />
                    <span className={'d-flex me-3'}>My Devices</span>
                    <Badge className="ms-auto align-self-center">
                      {account.user.installs.length > 99 ? '100+' : account.user.installs.length}
                    </Badge>
                  </NavDropdown.Item>
                  <NavDropdown.Item key={'my-licenses'} as={NavLink} className={'d-flex'} to="/account/licenses">
                    <FontAwesomeIcon icon="key" className="text-tertiary me-2" />
                    <span className={'d-flex me-3'}>My Licenses</span>
                    <Badge className="ms-auto align-self-center">
                      {account.user.licenses.length > 99 ? '100+' : account.user.licenses.length}
                    </Badge>
                  </NavDropdown.Item>
                  <NavDropdown.Item key={'my-workspaces'} as={NavLink} className={'d-flex'} to="/account/workspaces">
                    <FontAwesomeIcon icon="th-large" className="text-tertiary me-2" />
                    <span className={'d-flex me-3'}>My Workspaces</span>
                    <Badge className="ms-auto align-self-center">
                      {account.user.workspaces.length > 99 ? '100+' : account.user.workspaces.length}
                    </Badge>
                  </NavDropdown.Item>
                  <NavDropdown.Divider key={'account-divider-2'} />
                  <NavDropdown.Item key={'logout'} onClick={() => logout()}>
                    <FontAwesomeIcon icon="sign-out" className="text-tertiary me-2" />
                    <span>Logout</span>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                tempDisableNewLinks ? <>
                  <Nav.Link href="https://account.purpleio.uk/auth/login">
                    <Button variant="outline-tertiary">
                      <FontAwesomeIcon icon="sign-in" className="me-2" />
                      Login
                    </Button>
                  </Nav.Link>
                  <Nav.Link href="https://account.purpleio.uk/auth/register">
                    <Button variant="tertiary" className="text-primary">
                      <FontAwesomeIcon icon="user-edit" className="me-1" />
                      Register
                    </Button>
                  </Nav.Link>
                </> : <>
                  <Nav.Link onClick={openLogin}>
                    <Button variant="outline-tertiary">
                      <FontAwesomeIcon icon="sign-in" className="me-2" />
                      Login
                    </Button>
                  </Nav.Link>
                  <Nav.Link onClick={openRegister}>
                    <Button variant="tertiary" className="text-primary">
                      <FontAwesomeIcon icon="user-edit" className="me-1" />
                      Register
                    </Button>
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </>;
};

export default MainNav;
