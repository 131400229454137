import LicenseTypes from './api/LicenseTypes';
import Order from './api/Order';
import Account from './api/Account';

const Api = {
  Account: Account,
  LicenseTypes: LicenseTypes,
  Order: Order,
};
export default Api;
