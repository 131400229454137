import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AccountProvider} from './contexts/Account';
import { OrderProvider } from './contexts/Order';

import Layout from './pages/Layout';
import Loading from './pages/Loading';

const Home = lazy(() => import('./pages/Home'));

const AccountDevice = lazy(() => import('./pages/Account/Device'));
const AccountManage = lazy(() => import('./pages/Account/Manage'));
const AccountLicense = lazy(() => import('./pages/Account/License'));
const AccountOrder = lazy(() => import('./pages/Account/Order'));
const AccountWorkspaces = lazy(() => import('./pages/Account/Workspaces'));

const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./pages/Blog/Post'));

const Branding = lazy(() => import('./pages/Branding'));

const CompanyAbout = lazy(() => import('./pages/Company/About'));
const CompanyDataCentre = lazy(() => import('./pages/Company/DataCentre'));
const CompanyCareer = lazy(() => import('./pages/Company/Career'));
const CompanyCustomers = lazy(() => import('./pages/Company/Customers'));
const CompanyContact = lazy(() => import('./pages/Company/Contact'));

const Chrome = lazy(() => import('./pages/Chrome'));
const ChromePricing = lazy(() => import('./pages/Chrome/Pricing'));
const ChromeFeatures = lazy(() => import('./pages/Chrome/Features'));
const ChromeFaqs = lazy(() => import('./pages/Chrome/Faqs'));
const ChromeRelease = lazy(() => import('./pages/Chrome/Release'));
const ChromeDownload = lazy(() => import('./pages/Chrome/Download'));

const Client = lazy(() => import('./pages/Client'));
const ClientPricing = lazy(() => import('./pages/Client/Pricing'));
const ClientFeatures = lazy(() => import('./pages/Client/Features'));
const ClientCompare = lazy(() => import('./pages/Client/Compare'));
const ClientFaqs = lazy(() => import('./pages/Client/Faqs'));
const ClientRelease = lazy(() => import('./pages/Client/Release'));
const ClientDownload = lazy(() => import('./pages/Client/Download'));

const Hosting = lazy(() => import('./pages/Hosting'));
const HostingSharedStandard = lazy(() => import('./pages/Hosting/Shared/Standard'));
const HostingSharedPremium = lazy(() => import('./pages/Hosting/Shared/Premium'));
const HostingServersVirtualPrivateServers = lazy(() => import('./pages/Hosting/Servers/VirtualPrivateServers'));
const HostingServersDedicatedServers = lazy(() => import('./pages/Hosting/Servers/DedicatedServers'));
const HostingServersCoLocation = lazy(() => import('./pages/Hosting/Servers/CoLocation'));

const Legal = lazy(() => import('./pages/Legal'));
const LegalAbuse = lazy(() => import('./pages/Legal/Abuse'));
const LegalPrivacy = lazy(() => import('./pages/Legal/Privacy'));
const LegalCookie = lazy(() => import('./pages/Legal/Cookie'));
const LegalAcceptableUsePolicy = lazy(() => import('./pages/Legal/AcceptableUsePolicy'));
const LegalAntiSpam = lazy(() => import('./pages/Legal/AntiSpam'));
const LegalServiceLevelAgreement = lazy(() => import('./pages/Legal/ServiceLevelAgreement'));
const LegalEndUserLicenseAgreement = lazy(() => import('./pages/Legal/EndUserLicenseAgreement'));
const LegalDataProtectionAgreement = lazy(() => import('./pages/Legal/DataProtectionAgreement'));
const LegalDisasterRecoveryPlan = lazy(() => import('./pages/Legal/DisasterRecoveryPlan'));
const LegalGdpr = lazy(() => import('./pages/Legal/Gdpr'));
const LegalIncidentResponsePlan = lazy(() => import('./pages/Legal/IncidentResponsePlan'));
const LegalPciDss = lazy(() => import('./pages/Legal/PciDss'));
const LegalTerms = lazy(() => import('./pages/Legal/Terms'));
const LegalTermsHosting = lazy(() => import('./pages/Legal/Terms/Hosting'));
const LegalTermsVps = lazy(() => import('./pages/Legal/Terms/Vps'));
const LegalTermsDedicatedServer = lazy(() => import('./pages/Legal/Terms/DedicatedServer'));
const LegalTermsCoLocation = lazy(() => import('./pages/Legal/Terms/CoLocation'));
const LegalSecurity = lazy(() => import('./pages/Legal/Security'));

const Order = lazy(() => import('./pages/Order'));
const AddToOrder = lazy(() => import('./pages/Order/Add'));

const Partners = lazy(() => import('./pages/Partners'));

const Server = lazy(() => import('./pages/Server'));
const ServerPricing = lazy(() => import('./pages/Server/Pricing'));
const ServerFeatures = lazy(() => import('./pages/Server/Features'));
const ServerFaqs = lazy(() => import('./pages/Server/Faqs'));
const ServerRelease = lazy(() => import('./pages/Server/Release'));
const ServerDownload = lazy(() => import('./pages/Server/Download'));

const Security = lazy(() => import('./pages/Security'));

const NoPage = lazy(() => import('./pages/NoPage'));

function App() {
  return (
    <AccountProvider>
      <OrderProvider>
        <BrowserRouter>
          <React.Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />

                <Route path="/account/manage" element={<AccountManage />} />
                <Route path="/account/devices" element={<AccountDevice />} />
                <Route path="/account/licenses" element={<AccountLicense />} />
                <Route path="/account/orders" element={<AccountOrder />} />
                <Route path="/account/workspaces" element={<AccountWorkspaces />} />

                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/*" element={<BlogPost />} />

                <Route path="/branding" element={<Branding />} />

                <Route path="/company/about" element={<CompanyAbout />} />
                <Route path="/company/data-centre" element={<CompanyDataCentre />} />
                <Route path="/company/contact" element={<CompanyContact />} />
                <Route path="/company/customers" element={<CompanyCustomers />} />
                <Route path="/company/careers" element={<CompanyCareer />} />

                <Route path="/chrome" element={<Chrome />} />
                <Route path="/chrome/features" element={<ChromeFeatures />} />
                <Route path="/chrome/pricing" element={<ChromePricing />} />
                <Route path="/chrome/release" element={<ChromeRelease />} />
                <Route path="/chrome/download" element={<ChromeDownload />} />
                <Route path="/chrome/download/:type" element={<ChromeDownload />} />
                <Route path="/chrome/faqs" element={<ChromeFaqs />} />

                <Route path="/client" element={<Client />} />
                <Route path="/client/features" element={<ClientFeatures />} />
                <Route path="/client/pricing" element={<ClientPricing />} />
                <Route path="/client/release" element={<ClientRelease />} />
                <Route path="/client/compare" element={<ClientCompare />} />
                <Route path="/client/download" element={<ClientDownload />} />
                <Route path="/client/download/:type" element={<ClientDownload />} />
                <Route path="/client/faqs" element={<ClientFaqs />} />
                <Route path="/download" element={<ClientDownload />} />

                <Route path="/hosting" element={<Hosting />} />
                <Route path="/hosting/shared/standard" element={<HostingSharedStandard />} />
                <Route path="/hosting/shared/premium" element={<HostingSharedPremium />} />
                <Route path="/hosting/servers/virtual-private-servers" element={<HostingServersVirtualPrivateServers />} />
                <Route path="/hosting/servers/dedicated-servers" element={<HostingServersDedicatedServers />} />
                <Route path="/hosting/servers/co-location" element={<HostingServersCoLocation />} />

                <Route path="/legal" element={<Legal />} />
                <Route path="/legal/abuse" element={<LegalAbuse />} />
                <Route path="/legal/privacy" element={<LegalPrivacy />} />
                <Route path="/legal/cookie" element={<LegalCookie />} />
                <Route path="/legal/aup" element={<LegalAcceptableUsePolicy />} />
                <Route path="/legal/anti-spam" element={<LegalAntiSpam />} />
                <Route path="/legal/sla" element={<LegalServiceLevelAgreement />} />
                <Route path="/legal/eula" element={<LegalEndUserLicenseAgreement />} />
                <Route path="/legal/dpa" element={<LegalDataProtectionAgreement />} />
                <Route path="/legal/drp" element={<LegalDisasterRecoveryPlan />} />
                <Route path="/legal/gdpr" element={<LegalGdpr />} />
                <Route path="/legal/irp" element={<LegalIncidentResponsePlan />} />
                <Route path="/legal/pci-dss" element={<LegalPciDss />} />
                <Route path="/legal/terms" element={<LegalTerms />} />
                <Route path="/legal/terms/hosting" element={<LegalTermsHosting />} />
                <Route path="/legal/terms/dedicated-servers" element={<LegalTermsDedicatedServer />} />
                <Route path="/legal/terms/vps" element={<LegalTermsVps />} />
                <Route path="/legal/terms/co-location" element={<LegalTermsCoLocation />} />
                <Route path="/legal/security" element={<LegalSecurity />} />

                <Route path="/order" element={<Order />} />
                <Route path="/order/*" element={<AddToOrder />} />

                <Route path="/partners" element={<Partners />} />

                <Route path="/server" element={<Server />} />
                <Route path="/server/features" element={<ServerFeatures />} />
                <Route path="/server/pricing" element={<ServerPricing />} />
                <Route path="/server/release" element={<ServerRelease />} />
                <Route path="/server/download" element={<ServerDownload />} />
                <Route path="/server/download/:type" element={<ServerDownload />} />
                <Route path="/server/faqs" element={<ServerFaqs />} />

                <Route path="/security" element={<Security />} />

                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      </OrderProvider>
    </AccountProvider>
  );
}

export default App;
