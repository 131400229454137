import React, { useContext, useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { AccountContext } from '../../contexts/Account';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Dialog from '../';
import CodeInput from '../../components/CodeInput';
import CryptoService from '../../system/Crypto';
import Cache from '../../system/Cache';
import Api from '../../Api';

import styles from './styles.module.scss';

const LoginDialog = ({ closeDialog, openRegister, openForgotPassword }) => {
  const cache = new Cache();
  const crypto = new CryptoService();
  const [loading, setLoading] = useState(false);
  const [accountState, dispatchAccount] = useContext(AccountContext);
  const [data, setData] = useState(() => {
    let cacheUsername = '';
    const rememberMe = cache.get('remember_me');
    if (null !== rememberMe) {
      cacheUsername = rememberMe;
    }

    return {
      username: cacheUsername,
      password: '',
      two_step: {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: ''
      },
      remember_me: null !== rememberMe
    };
  });
  const [errorFields, setErrorFields] = useState([]);
  const [requiresTwoStep, setRequiresTwoStep] = useState(false);

  const validateFields = () => new Promise((resolve, reject) => {
    Promise.all(Object.keys(data).map((key) => {
      return validateField(key, data[key]).then(() => true).catch(() => false);
    })).then((result) => result.includes(false) ? reject() : resolve());
  });

  const validateField = (field, value) => new Promise((resolve, reject) => {
    switch (field) {
      case 'username':
        if (String(value).toLowerCase().match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        )) {
          resolve();
        } else {
          errorFields.push({
            key: 'username',
            message: 'Please enter a valid email address.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'password':
        if (value.length < 8) {
          errorFields.push({
            key: 'password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'two_step':
        if (!requiresTwoStep) {
          resolve();
          return;
        }

        Promise.all(Object.keys(data.two_step).map((key) => {
          return data.two_step[key].match(/^[0-9]$/) ? true : false;
        })).then((result) => {
          if (result.includes(false)) {
            setErrorFields([{
              key: 'two_step',
              message: 'Invalid two step code.'
            }]);
            reject();
            return;
          }

          resolve();
        });

        break;
      default:
        // remember me can be auto validated
        resolve();
        break;
    }
  });

  const updateField = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    setData(newData);
    setErrorFields([]);
  };

  const updateTwoStep = (index, code) => {
    const newData = { ...data };
    newData.two_step[index] = code;

    setData(newData);
    setErrorFields([]);
  }

  const toggleRememberMe = () => {
    const newData = { ...data };
    newData['remember_me'] = !data.remember_me;
    setData(newData);
  };

  const submit = () => {
    setLoading(true);
    validateFields().then(() => {
      Api.Account.login(data, requiresTwoStep).then((response) => {
        setLoading(false);
        if (response.token) {
          const accountData = {
            ...accountState,
            token: response.token,
            refresh_token: response.refresh_token
          };

          dispatchAccount(accountData);

          if (data.remember_me) {
            cache.set('remember_me', data.username);
          }

          Api.Account.get().then((user) => {
            if (user['public'] && user['key']) {
              crypto.setSalt(user['reference']);

              crypto.importKey('public', JSON.parse(atob(user['public']))).then((publicKey) => {
                crypto.setPassphrase(data.password).then(() => {
                  crypto.importKey('private', JSON.parse(atob(user['key'])), true).then((privateKey) => {
                    user.public = publicKey;
                    user.key = privateKey;

                    dispatchAccount({
                      ...accountData,
                      user: user
                    });

                    closeDialog();
                  }).catch((e) => {
                    setLoading(false);
                    setErrorFields([{
                      key: 'login',
                      message: 'Failed to load private key, if this persists please contact support.'
                    }]);
                  });
                }).catch((e) => {
                  setLoading(false);
                  setErrorFields([{
                    key: 'login',
                    message: 'Failed to set passphrase for private key.'
                  }]);
                });
              }).catch((e) => {
                setLoading(false);
                setErrorFields([{
                  key: 'login',
                  message: 'Failed to load public key, if this persists please contact support.'
                }]);
              });
            } else {
              dispatchAccount({
                ...accountData,
                user: user
              });
              closeDialog();
            }
          }).catch((e) => {
            setLoading(false);
            setErrorFields([{
              key: 'login',
              message: 'Failed to get account information, if this persists please contact support.'
            }])
          });
        } else if ('two_step' === response.data.field && response.data.required) {
          setRequiresTwoStep(true);
          setErrorFields([]);
        } else {
          setErrorFields([{
            key: response.data.field,
            message: response.message
          }]);
        }
      }).catch((e) => {
        setLoading(false);
        setErrorFields([{
          key: 'login',
          message: 'An error occurred, please try again later!'
        }])
        console.log('error', e);
      });
    }).catch((e) => {
      setLoading(false);
    });
  };

  return <Dialog title={<>
    <FontAwesomeIcon icon={requiresTwoStep ? 'clock' : 'sign-in'} className="me-2" />
    {requiresTwoStep ? 'Authentication Code' : 'Login'}
  </>} closeDialog={closeDialog}>
    <div className={styles.dialog}>
      <form id={'login_dialog'}>
        {requiresTwoStep ? <Row>
          <Col xs={12}>
            <label htmlFor="two_step" className="mb-2">
              <FontAwesomeIcon icon="clock" className="me-2" />
              Two-step authentication code
            </label>
            <p className="grey-text">
              Enter your 2FA code from your Authentication App.
            </p>
          </Col>
          <CodeInput code={data.two_step} updateCode={updateTwoStep} errorFields={errorFields} submit={submit} />
          {0 < errorFields.length && 0 < errorFields.filter(field => ['two_step'].includes(field.key)).length ? <Col className="mt-3">
            <Alert variant="danger" className="p-2 m-0">
              <strong className="bold me-3">
                <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1" />
                Error
              </strong>
              {errorFields.filter(field => 'two_step' === field.key)[0].message}
            </Alert>
          </Col> : ''}
          <Col xs={12} className="mt-3">
            <Button onClick={submit} disabled={Object.keys(data.two_step).map((k) => data.two_step[k]).join('').length !== 6 || loading} variant="success" className="w-100">
              <FontAwesomeIcon icon={loading ? 'spinner' : 'chevron-right'} className={'me-2 ' + (loading ? 'fa-spin' : '')} />
              {loading ? 'Loading...' : 'Verify'}
            </Button>
          </Col>
        </Row> : <Row>
          {0 < errorFields.length && 0 < errorFields.filter(field => ['login'].includes(field.key)).length ? <Col className="mt-3 mb-3">
            <Alert variant="danger" className="p-2 m-0">
              <strong className="bold me-3">
                <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1" />
                Error
              </strong>
              {errorFields.filter(field => 'login' === field.key)[0].message}
            </Alert>
          </Col> : ''}
          <Col xs={12}>
            <fieldset>
              <label htmlFor="username" className="mb-1">
                <FontAwesomeIcon icon="envelope" className="me-2" />
                Email address
              </label>
              <input
                type="email"
                id="username"
                name="username"
                value={data.username}
                onChange={updateField}
                placeholder="i.e. me@example.com"
                onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
                className={errorFields.includes('username') ? styles.input__error : ''}
                autoComplete="on"
                required
              />
            </fieldset>
            {0 < errorFields.filter(field => 'username' === field.key).length ? <div className={'red-text'}>
              <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'} />
              {errorFields.filter(field => 'username' === field.key)[0].message}
            </div> : ''}
          </Col>
          <Col xs={12} className="mt-3">
            <fieldset>
              <label htmlFor="password" className="mb-1">
                <FontAwesomeIcon icon="lock" className="me-2"/>
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={data.password}
                onChange={updateField}
                placeholder="Enter your password"
                onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
                className={0 < errorFields.filter(field => 'password' === field.key).length ? styles.input__error : ''}
                autoComplete="on"
                required
              />
              {0 < errorFields.filter(field => 'password' === field.key).length ? <div className={'red-text'}>
                <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
                {errorFields.filter(field => 'password' === field.key)[0].message}
              </div> : ''}
            </fieldset>
            <button type={'button'} onClick={() => openForgotPassword()} className="ms-2 float-end transparent border-0 text-tertiary">
              Forgot password?
            </button>
          </Col>
          <Col xs={12} className="mt-3">
            <fieldset>
              <input
                type="checkbox"
                id="remember_me"
                name="remember_me"
                onChange={toggleRememberMe}
                checked={data.remember_me}
                className="me-2 w-auto"
              />
              <label htmlFor="remember_me">
                Remember me
              </label>
            </fieldset>
          </Col>
          <Col xs={12} className="mt-3">
            <Button type={'submit'} onClick={submit} disabled={loading} variant="success" className="w-100">
              <FontAwesomeIcon icon={loading ? 'spinner' : 'sign-in'} className={'me-2 ' + (loading ? 'fa-spin' : '')} />
              {loading ? 'Loading...' : 'Log In'}
            </Button>
          </Col>
          <Col xs={12} className="mt-2 text-center">
            Don't have an account?
            <button type={'button'} onClick={openRegister} className="ms-2 transparent border-0 text-tertiary">
              Register
            </button>
          </Col>
        </Row> }
      </form>
    </div>
  </Dialog>;
}

export default LoginDialog;
