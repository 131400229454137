import React, { useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Dialog from '../';
import Api from '../../Api';

import styles from './styles.module.scss';

const RegisterDialog = ({ closeDialog, openLogin }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });
  const [errorFields, setErrorFields] = useState([]);

  const validateFields = () => new Promise((resolve, reject) => {
    Promise.all(Object.keys(data).map((key) => {
      return validateField(key, data[key]).then(() => true).catch(() => false);
    })).then((result) => result.includes(false) ? reject() : resolve());
  });

  const validateField = (field, value) => new Promise((resolve, reject) => {
    switch (field) {
      case 'first_name':
      case 'last_name':
        if (String(value).toLowerCase().match(
          /^[<>,.\-_£$!@%^*_+={}\]\]:/]$/
        ) || 0 === value.length) {
          errorFields.push({
            key: field,
            message: 'Please enter a valid ' + field.replace('_', ' ') + '.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'email':
        if (String(value).toLowerCase().match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        )) {
          resolve();
        } else {
          errorFields.push({
            key: 'email',
            message: 'Please enter a valid email address.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'password':
        if (value.length < 8) {
          errorFields.push({
            key: 'password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'confirm_password':
        if (value.length < 8) {
          errorFields.push({
            key: 'confirm_password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'confirm_password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (value !== data.password) {
          errorFields.push({
            key: 'confirm_password',
            message: 'Passwords do not match.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      default:
        // company can be auto validated (optional field)
        resolve();
        break;
    }
  });

  const updateField = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    setData(newData);
    setErrorFields([]);
  };

  const submit = () => {
    setLoading(true);
    validateFields().then(() => {
      Api.Account.register(data).then((response) => {
        setLoading(false);
        console.log(response, response.violations);

        if (undefined === response.violations) {
          setTimeout(() => {
            openLogin();
          }, 1000);
        } else {
          response.violations.forEach((violation) => {
            console.log(violation);
            if ('This value should not be blank.' === violation.message) return;

            console.log(violation);

            setErrorFields([{
              key: violation.propertyPath,
              message: 'This value is already used.' === violation.message ? 'Error registering account' : violation.message
            }]);
          })
        }
      }).catch((e) => {
        setLoading(false);
        setErrorFields([{
          key: 'login',
          message: 'An error occurred, please try again later!'
        }])
        console.log('error', e);
      });
    }).catch((e) => {
      setLoading(false);
      console.log('error in validation', e);
    });
  };

  return <Dialog title={<>
    <FontAwesomeIcon icon="pencil" className="me-2" />
    Create Account
  </>} closeDialog={closeDialog}>
    <div className={styles.dialog}>
      <form id={'register_dialog'}>
        <Row>
          <Col xs={12} md={6}>
            <fieldset>
              <label htmlFor="first_name" className="mb-1">
                <FontAwesomeIcon icon="user" className="me-2"/>
                Full name
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={data.first_name}
                onChange={updateField}
                placeholder="First name (i.e. John)"
                onKeyUp={(e) => 'Enter' === e.key ? submit() : ''}
                className={errorFields.includes('first_name') ? styles.input__error : ''}
                autoComplete={'on'}
                required
              />
              {0 < errorFields.filter(field => 'first_name' === field.key).length ? <div className={'red-text'}>
                <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
                {errorFields.filter(field => 'first_name' === field.key)[0].message}
              </div> : ''}
            </fieldset>
          </Col>
          <Col xs={12} md={6}>
            <label htmlFor="last_name" className="mb-1">
              &nbsp;
            </label>
            <fieldset>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={data.last_name}
                placeholder="Last name (i.e. Doe)"
                onChange={updateField}
                className={errorFields.includes('last_name') ? styles.input__error : ''}
                autoComplete={'on'}
                required
              />
              {0 < errorFields.filter(field => 'last_name' === field.key).length ? <div className={'red-text'}>
                <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
                {errorFields.filter(field => 'last_name' === field.key)[0].message}
              </div> : ''}
            </fieldset>
          </Col>
          <Col xs={12} className="mt-3">
            <fieldset>
              <label htmlFor="email" className="mb-1">
                <FontAwesomeIcon icon="envelope" className="me-2"/>
                Email address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={data.email}
                placeholder="i.e. me@example.com"
                onChange={updateField}
                className={errorFields.includes('email') ? styles.input__error : ''}
                autoComplete={'off'}
                required
              />
            </fieldset>
            {0 < errorFields.filter(field => 'email' === field.key).length ? <div className={'red-text'}>
              <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
              {errorFields.filter(field => 'email' === field.key)[0].message}
            </div> : ''}
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <fieldset>
              <label htmlFor="password" className="mb-1">
                <FontAwesomeIcon icon="lock" className="me-2"/>
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={data.password}
                onChange={updateField}
                placeholder="Enter a password"
                className={errorFields.includes('password') ? styles.input__error : ''}
                autoComplete={'off'}
                required
              />
            </fieldset>
            {0 < errorFields.filter(field => 'password' === field.key).length ? <div className={'red-text'}>
              <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
              {errorFields.filter(field => 'password' === field.key)[0].message}
            </div> : ''}
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <fieldset>
              <label htmlFor="confirm_password" className="mb-1">
                <FontAwesomeIcon icon="lock" className="me-2"/>
                Confirm Password
              </label>
              <input
                type="password"
                id="confirm_password"
                name="confirm_password"
                value={data.confirm_password}
                onChange={updateField}
                placeholder="Confirm your password"
                className={errorFields.includes('confirm_password') ? styles.input__error : ''}
                autoComplete={'off'}
                required
              />
            </fieldset>
            {0 < errorFields.filter(field => 'confirm_password' === field.key).length ? <div className={'red-text'}>
              <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
              {errorFields.filter(field => 'confirm_password' === field.key)[0].message}
            </div> : ''}
          </Col>
          <Col xs={12} className="mt-3">
            <Row>
              <Col>
                <Button type={'submit'} onClick={submit} disabled={loading} variant="success" className="w-100">
                  <FontAwesomeIcon icon="pencil" className="me-2"/>
                  {loading ? 'Loading...' : 'Create Account'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2 text-center">
                Already have an account?
                <button type={'button'} onClick={openLogin} className="ms-2 transparent border-0 text-tertiary">
                  Login
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  </Dialog>;
}

export default RegisterDialog;
